<template>
	<div>
		<div class="item__row item__ac">
			<h2>{{ $route.query.name }}</h2>

			<v-btn
				small
				class="mx-2"
				fab
				dark
				color="indigo"
				@click="chooseTypeFunction(1)"
			>
				<v-icon dark> mdi-plus </v-icon>
			</v-btn>
		</div>

		<v-data-table
			:headers="headers"
			:items="items"
			:page="page"
			:loading="loading"
			:options.sync="options"
			:server-items-length="totalPage"
		>
			<template v-slot:item.data="{ item }">
				<p class="mb-2">На каз: {{ item.name_kk }}</p>
				<p class="mb-2">На рус: {{ item.name_ru }}</p>
				<p class="mb-6">На анг: {{ item.name_en }}</p>
				<!--
            <p class="mb-2" v-if="JSON.parse(item.data)">На каз: {{JSON.parse(item.data).name_kk}}</p>
            <p class="mb-2" v-if="JSON.parse(item.data)">На рус: {{JSON.parse(item.data).name_ru}}</p>
            <p class="mb-6" v-if="JSON.parse(item.data)">На анг: {{JSON.parse(item.data).name_en}}</p>
        -->
			</template>

			<template v-slot:item.created_at="{ item }">
				{{ formatDate(item.created_at) }}
			</template>
			<template v-slot:item.updated_at="{ item }">
				{{ formatDate(item.updated_at) }}
			</template>
			<template v-slot:item.action="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<i
							class="mdi mdi-pencil"
							@click="show(item.id, item)"
							v-bind="attrs"
							v-on="on"
						></i>
					</template>
					<span>Редактировать</span>
				</v-tooltip>

				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<i
							style="margin: 10px"
							class="mdi mdi-trash-can-outline"
							@click="openDeleteModal(item.id)"
							v-bind="attrs"
							v-on="on"
						></i>
					</template>
					<span>Удалить</span>
				</v-tooltip>
			</template>
		</v-data-table>

		<v-dialog v-model="destroyModal" width="500">
			<v-card class="pa-6">
				<h3 class="mb-4">Удалить запись</h3>
				<v-btn type="submit" depressed color="primary" @click="deleteItem()">
					Да
				</v-btn>

				<v-btn depressed color="default" @click="destroyModal = false">
					Отмена
				</v-btn>
			</v-card>
		</v-dialog>

		<v-dialog v-model="openModal" width="500">
			<v-card class="pa-6">
				<v-form
					@submit.prevent="callFunction()"
					ref="form"
					class="sign__page__block"
				>
					<h3 class="mb-4" v-if="type == 1">Создать</h3>
					<h3 class="mb-4" v-else>Редактировать</h3>
					<div class="item__column">
						<v-text-field
							v-model="name_ru"
							label="Название"
							required
							outlined
							class="input"
							:rules="nameRules"
						></v-text-field>
					</div>
					<div class="item__column">
						<vue-editor v-model="content_ru"> </vue-editor>
					</div>

					<div class="item__column">
						<v-text-field
							v-model="name_en"
							label="Название английском"
							required
							outlined
							class="input"
							:rules="nameRules"
						></v-text-field>
					</div>
					<div class="item__column">
						<vue-editor v-model="content_en"> </vue-editor>
					</div>

					<div class="item__column">
						<v-text-field
							v-model="name_kk"
							label="Название казахском"
							required
							outlined
							class="input"
							:rules="nameRules"
						></v-text-field>
					</div>
					<div class="item__column">
						<vue-editor v-model="content_kk"> </vue-editor>
					</div>

					<div>
						<v-file-input
							chips
							counter
							multiple
							show-size
							small-chips
							truncate-length="15"
							v-model="files"
						></v-file-input>
					</div>

					<div class="item__column">
						<div
							v-for="file in uploadedFiles"
							:key="file.id"
							class="item__row item__ac pointer mb-3 images"
						>
							<!-- <p class="mr-2 mb-0">{{file.path.split('/')[file.path.split('/').length-1]}}</p> -->
							<img class="mr-2" :src="$API_ROOT + file.path" />
							<i
								class="mdi mdi-trash-can-outline"
								@click="removeFile(file.id)"
							></i>
						</div>
					</div>

					<v-btn type="submit" depressed color="primary">
						Сохранить изменения
					</v-btn>

					<v-btn depressed color="default" @click="openModal = false">
						Отмена
					</v-btn>
				</v-form>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
export default {
	props: ["showModal"],
	name: "News",
	data() {
		return {
			items: [],
			loading: false,
			numberOfPages: 0,
			totalPage: 0,
			openModal: false,
			page: 0,
			options: {
				itemsPerPage: 5,
				page: 1
			},
			destroyModal: false,
			nameRules: [(v) => !!v || "Заполните поле"],
			descriptionRules: [(v) => !!v || "Заполните поле"],
			files: [],
			type: 0,
			newsId: "",
			me: null,
			selectedUser: null,
			uploadedFiles: [],
			title: null,
			description: null,
			title_eng: null,
			title_kaz: null,
			description_eng: null,
			description_kaz: null,
			headers: [
				{
					text: "№",
					align: "start",
					sortable: false,
					value: "id"
				},
				{ text: "Данные", value: "data" },
				{ text: "Дата создание", value: "created_at" },
				{ text: "Дата редактирование", value: "updated_at" },
				{ text: "Действие", value: "action" }
			]
		};
	},
	methods: {
		fetch() {
			this.loading = true;

			let url = "segments?per_page=50&page='+this.options.page";
			this.$axios({
				method: "get",
				url: this.$API_URL + this.$API_VERSION + url,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.items = response.data.data;

					this.loading = false;
					this.numberOfPages = response.data.total;
					this.totalPage = response.data.total;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		formatDate(date) {
			if (date) {
				let splitter = date.includes("T") ? "T" : " ";
				let d = date.split(splitter)[0].split("-");
				let time = date.split(splitter)[1].split(":");
				return d[2] + "-" + d[1] + "-" + d[0] + " " + time[0] + ":" + time[1];
			}
		},
		removeFile(fileId) {
			this.$axios({
				method: "delete",
				url: this.$API_URL + this.$API_VERSION + "page/file/" + fileId,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.$toast.open({
						message: response.data.message,
						type: "success",
						position: "bottom",
						duration: 4000,
						queue: true
					});
					this.fetch();
					this.openModal = false;
				})
				.catch((error) => {
					console.warn(error);
				});
		},
		getUser() {
			this.$axios({
				method: "get",
				url: this.$API_URL + this.$API_VERSION + "me",
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.me = response.data;
				})
				.catch((error) => {
					console.warn(error);
				});
		},
		chooseTypeFunction(type) {
			this.type = type;
			this.openModal = true;
		},
		callFunction() {
			this.type == 1 ? this.create() : this.update();
		},
		create() {
			let obj = {
				name_kk: this.name_kk,
				name_ru: this.name_ru,
				name_en: this.name_en,
				content_kk: this.content_kk,
				content_ru: this.content_ru,
				content_en: this.content_en,
				type: this.$route.query.type
			};
			//this.$refs.form.validate();
			this.createData(obj, this.files);

			this.$refs.form.reset();
			this.openModal = false;
		},
		createData(obj, files = null) {
			let contractForm = obj;
			/*console.log(obj);
			let contractForm = new FormData();
			contractForm.append(obj);
			*/

			if (files)
				for (var i = 0; i < files.length; i++) {
					contractForm.append("files[]", files[i]);
				}

			this.$axios
				.post(this.$API_URL + this.$API_VERSION + "segments", contractForm, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
						//"Content-Type": "multipart/form-data",
					}
				})
				.then((response) => {
					this.showModal = false;
					this.$toast.open({
						message: response.data.message,
						type: "success",
						position: "bottom",
						duration: 4000,
						queue: true
					});
					this.fetch();
				})
				.catch((error) => {
					if (error.response && error.response.status == 422) {
						this.$toast.open({
							message: "Заполните все поля",
							type: "error",
							position: "bottom",
							duration: 4000,
							queue: true
						});
					}
				});
		},
		updateData(formData, files, id) {
			this.$axios
				.put(this.$API_URL + this.$API_VERSION + "segments/" + id, formData, {
					headers: {
						Authorization: `Bearer ${localStorage.getItem("access_token")}`
					}
				})
				.then((response) => {
					this.$toast.open({
						message: response.data.message,
						type: "success",
						position: "bottom",
						duration: 4000,
						queue: true
					});

					if (files.length > 0) this.uploadFiles(files, id);

					this.fetch();
					this.openModal = false;
				})
				.catch((error) => {
					if (error.response && error.response.status == 422) {
						this.$toast.open({
							message: "Заполните все поля",
							type: "error",
							position: "bottom",
							duration: 4000,
							queue: true
						});
					}
				});
		},
		uploadFiles(files, id) {
			let contractForm = new FormData();
			for (var i = 0; i < files.length; i++) {
				contractForm.append("files[]", files[i]);
			}
			this.$axios
				.post(
					this.$API_URL + this.$API_VERSION + "page/files/" + id,
					contractForm,
					{
						headers: {
							Authorization: `Bearer ${localStorage.getItem("access_token")}`,
							"Content-Type": "multipart/form-data"
						}
					}
				)
				.then((response) => {
					this.title = null;
					this.description = null;
					console.log(response);
					this.$toast.open({
						message: "Успешно создано",
						type: "success",
						position: "bottom",
						duration: 4000,
						queue: true
					});
					this.fetch();
					this.openModal = false;
					this.type = 0;
					this.$refs.form.reset();
				})
				.catch((error) => {
					if (error.response && error.response.status == 422) {
						this.$toast.open({
							message: "Заполните все поля",
							type: "error",
							position: "bottom",
							duration: 4000,
							queue: true
						});
					}
				});
		},
		show(id, item, files) {
			this.id = id;
			this.openModal = true;
			this.name_kk = item.name_kk;
			this.name_ru = item.name_ru;
			this.name_en = item.name_en;
			this.content_kk = item.content_kk;
			this.content_ru = item.content_ru;
			this.content_en = item.content_en;
			this.sort = item.sort;
			this.uploadedFiles = files;
		},
		makeJson(item) {
			if (item) return JSON.parse(item);
		},
		openDeleteModal(id) {
			this.destroyModal = true;
			this.selectedUser = id;
		},
		deleteItem() {
			this.$axios({
				method: "delete",
				url: this.$API_URL + this.$API_VERSION + "page/" + this.selectedUser,
				headers: {
					Authorization: `Bearer ${localStorage.getItem("access_token")}`
				}
			})
				.then((response) => {
					this.title = response.data.title;
					this.description = response.data.description;
					this.fetch();
					this.destroyModal = false;
					this.openModal = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		update() {
			let obj = {
				name_kk: this.name_kk,
				name_ru: this.name_ru,
				name_en: this.name_en,
				content_kk: this.content_kk,
				content_ru: this.content_ru,
				content_en: this.content_en,
				sort: 1
			};

			this.updateData(obj, this.files, this.id);
		}
	},
	mounted() {
		this.fetch();
		this.getUser();
	},
	beforeMount() {},
	watch: {
		options: {
			handler(val) {
				if (val.itemsPerPage < 0) {
					val.itemsPerPage = this.totalPage;
					this.fetch();
				} else {
					this.fetch();
				}
			}
		},

		deep: true
	}
};
</script>

<style lang="scss">
.news__list {
	img {
		width: 100px;
		height: 100px;
		object-fit: cover;
	}
}
.quillWrapper {
	margin-bottom: 20px;
}
</style>
